@font-face {
  font-family: "Proxima Soft";
  src: url("./assets/styles/fonts/ProximaSoft-SemiBold.eot");
  src: local("Proxima Soft SemiBold"), local("ProximaSoft-SemiBold"),
    url("./assets/styles/fonts/ProximaSoft-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/styles/fonts/ProximaSoft-SemiBold.woff2") format("woff2"),
    url("./assets/styles/fonts/ProximaSoft-SemiBold.woff") format("woff"),
    url("./assets/styles/fonts/ProximaSoft-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("./assets/styles/fonts/ProximaSoft-Medium.eot");
  src: local("Proxima Soft Medium"), local("ProximaSoft-Medium"),
    url("./assets/styles/fonts/ProximaSoft-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/styles/fonts/ProximaSoft-Medium.woff2") format("woff2"),
    url("./assets/styles/fonts/ProximaSoft-Medium.woff") format("woff"),
    url("./assets/styles/fonts/ProximaSoft-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Soft";
  src: url("./assets/styles/fonts/ProximaSoft-Bold.eot");
  src: local("Proxima Soft Bold"), local("ProximaSoft-Bold"),
    url("./assets/styles/fonts/ProximaSoft-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/styles/fonts/ProximaSoft-Bold.woff2") format("woff2"),
    url("./assets/styles/fonts/ProximaSoft-Bold.woff") format("woff"),
    url("./assets/styles/fonts/ProximaSoft-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Soft";
  src: url("./assets/styles/fonts/ProximaSoft-Regular.eot");
  src: local("Proxima Soft Regular"), local("ProximaSoft-Regular"),
    url("./assets/styles/fonts/ProximaSoft-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/styles/fonts/ProximaSoft-Regular.woff2") format("woff2"),
    url("./assets/styles/fonts/ProximaSoft-Regular.woff") format("woff"),
    url("./assets/styles/fonts/ProximaSoft-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0px;
  font-family: "Proxima Soft";
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
