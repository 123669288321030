.img {
  height: 96vh;
  /*width: 100vw;*/
  width: 24vw;
  object-fit: fill;
  position: absolute;
  border-radius: 50px;
  margin-top: 2vh;
}

.frame {
  height: 100vh;
  width: 25vw;
  /*width: 100vw;*/
  object-fit: fill;
  position: absolute;
}

.trackheader {
  position: absolute;
  top: 25px;
  left: 0px;
  width: 100%;
}

.trackfooter-controls {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  position: fixed;
  flex-direction: column;
  width: 100%;
  left: 0px;
  bottom: 135px;
}

.imgOverlay {
  height: 96vh;
  /*width: 100vw;*/
  width: 24vw;
  background-color: rgba(0, 0, 0, 0.55);
  position: relative;
  border-radius: 50px;
  margin-top: 2vh;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.headerTitle {
  font-weight: 600;
  color: white;
  font-size: 19px;
}

.headerSubtitle {
  font-weight: 400;
  color: white;
  font-size: 15px;
}

.spaceHeight5 {
  height: 2vh;
}

.spaceWidth3 {
  width: 3vw;
}

.spaceHeight200 {
  height: 40vh;
}

.controllerOuterContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.controllerContainer {
  width: 80px;
  height: 80px;
  display: flex;
  align-self: center;
  background-color: rgba(255, 255, 255, 0.2);
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.track-main-app {
  display: flex;
  width: 100%;
  background: #ededed;
  justify-content: center;
  position: relative;
  height: 100vh;
  background: url("../assets/images/pbr_bg.png");
}

.track-main {
  width: 24vw;
  margin: 0px auto;
  overflow: hidden;
}

.logo {
  margin: 8vh 0 0vh 0;
}

.downloadBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.downloadBtn {
  padding: 12px 20px;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: white !important;
  color: rgba(102, 26, 95, 1) !important;
  font-family: "Proxima Soft";
  font-weight: bold !important;
}

@media screen and (max-width: 62rem) {
  .track-main {
    width: 100%;
  }

  .imgOverlay {
    width: 100%;
  }

  .img {
    width: 100%;
  }

  .img {
    height: 100vh;
    /*width: 100vw;*/
    object-fit: fill;
    position: absolute;
    border-radius: 0px;
    width: 100%;
    margin-top: 0px;
  }

  .imgOverlay {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    position: absolute;
    border-radius: 0px;
    margin-top: 0px;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .headerTitle {
    font-weight: 600;
    color: white;
    font-size: 19px;
  }

  .headerSubtitle {
    font-weight: 400;
    color: white;
    font-size: 15px;
  }

  .spaceHeight5 {
    height: 1.5vh;
  }

  .spaceWidth3 {
    width: 3vw;
  }

  .spaceHeight200 {
    height: 52vh;
  }

  .controllerOuterContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .controllerContainer {
    width: 100px;
    height: 100px;
    display: flex;
    align-self: center;
    background-color: rgba(255, 255, 255, 0.2);
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  .track-main-app {
    display: flex;
    width: 100%;
    background: #ededed;
  }

  .track-main {
    width: 360px;
    margin: 0px auto;
    overflow: hidden;
  }

  .logo {
    margin: 0 0 0 0;
  }

  .frame {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .frame {
    width: 360px;
  }

  .imgOverlay {
    width: 360px;
  }

  .img {
    width: 360px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1500px) {
  .frame {
    width: 410px;
  }

  .imgOverlay {
    width: 410px;
  }

  .img {
    width: 410px;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1700px) {
  .frame {
    width: 450px;
  }

  .imgOverlay {
    width: 450px;
  }

  .img {
    width: 450px;
  }
}

/* Modal */
.meditations-modal .modal-footer .download-app-button {
  border-radius: 25px;
  background: linear-gradient(
    122deg,
    #9797df 0%,
    #38c5db 32.2%,
    #12c2e9 64.25%,
    #60c9cd 86.59%
  );
  width: 269px;
  height: 49px;
  backdrop-filter: blur(20.5px);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 162.5% */
  text-transform: uppercase;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0px;
  text-decoration: none;
  cursor: pointer;
}

.meditations-modal .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.meditations-modal .modal-body p {
  color: #262626;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 162.5% */
  width: 260px;
  overflow: hidden;
  margin: 0px;
}

.meditations-modal .modal-footer {
  justify-content: center !important;
}

.meditations-modal .btn-close {
  width: 38px;
  height: 38px;
  display: block;
  position: absolute;
  top: -18px;
  right: -18px;
  background: #fff;
  border-radius: 50px;
  outline: none;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.09);
}

.meditations-modal .btn-close:before {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.95283 5.53503L12.2058 0.282032C12.3947 0.09918 12.6479 -0.0021012 12.9108 3.30587e-05C13.1737 0.00216732 13.4252 0.107546 13.6111 0.29344C13.797 0.479335 13.9024 0.730848 13.9045 0.993733C13.9066 1.25662 13.8054 1.50981 13.6225 1.6987L8.3695 6.9517L13.6225 12.2058C13.8054 12.3947 13.9066 12.6479 13.9045 12.9108C13.9024 13.1737 13.797 13.4252 13.6111 13.6111C13.4252 13.797 13.1737 13.9024 12.9108 13.9045C12.6479 13.9066 12.3947 13.8054 12.2058 13.6225L6.95283 8.3695L1.69983 13.6225C1.51094 13.8054 1.25775 13.9066 0.994867 13.9045C0.731981 13.9024 0.480468 13.797 0.294574 13.6111C0.108679 13.4252 0.00330111 13.1737 0.00116685 12.9108C-0.000967407 12.6479 0.100313 12.3947 0.283165 12.2058L5.53616 6.95283L0.282032 1.69983C0.09918 1.51094 -0.0021012 1.25775 3.30587e-05 0.994867C0.00216732 0.731981 0.107546 0.480468 0.29344 0.294574C0.479335 0.108679 0.730848 0.00330111 0.993733 0.00116685C1.25662 -0.000967407 1.50981 0.100313 1.6987 0.283165L6.95283 5.53503Z' fill='%239A9A9A'/%3E%3C/svg%3E");
  background-size: 14px 14px;
  background-repeat: no-repeat;
  height: 14px;
  width: 14px;
  content: "";
  position: absolute;
  top: 12px;
  left: 12px;
}

.meditations-modal .modal-header {
  padding: 0px !important;
  border-bottom: 0px solid #dee2e6;
}

.meditations-modal .modal-footer {
  border: none;
  border-top: 0px solid #dee2e6;
  padding-top: 0px !important;
  padding-bottom: 1.5rem;
}

.meditations-modal .modal-content {
  border-radius: 14px;
  height: 185px;
}

.meditations-modal .modal-dialog {
  margin: 1.75rem auto;
  width: 300px;
}

.meditations-modal.modal {
  padding-left: 0px !important;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  bottom: 25px;
  top: auto;
  position: fixed;
  height: auto;
}
