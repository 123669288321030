.App {
  height: 100vh;

}

.img{
  height: 100vh;
  width: 100vw;
  object-fit:fill;
  position: absolute;
  
}
.imgOverlay{
  height: 100vh;
  width: 100%;
  background-color:rgba(0, 0, 0, .55);
  position: absolute;
}
.header{
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.headerTitle{
  font-weight: 600;
  color: white;
  font-size: 19px;
  
}
.headerSubtitle{
  font-weight: 300;
  color: white;
  font-size: 12px;
}
.spaceHeight5{
 height : 5px
}
.spaceHeight50{
 height : 50px
}
.spaceWidth3{
 width : 3vw
}
.spaceHeight200{
  height: 55vh;
}
.controllerOuterContainer{
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

}
.controllerContainer{
  width: 100px;
  height: 100px;
  display: flex;
  align-self: center;
  background-color: rgba(255, 255, 255, .2);
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}



