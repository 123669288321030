.pbr-block {
  width: 100%;
  height: 100vh;
  background: url("../assets/images/pbr_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.pbr-content {
  width: 100%;
  display: flex;
  flex: 1;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.pbr-content-box {
  width: 31.25rem;
  background: #0b1e53;
  border-radius: 0.875rem;
  padding: 4rem 2rem;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.pbr-content-text {
  width: 100%;
  justify-content: center;
  align-items: center;
  /* flex: 1;*/
  /*height: 20%;*/
  align-items: center;
  display: flex;
}
.pbr-content-text b {
  font-weight: 700;
  text-transform: capitalize;
}
.pbr-content-box p {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #fff;
  text-align: center;
  margin: 0rem;
  display: inline-block;
  font-weight: 500;
  /* text-align: justify; */
}

.pbr-content-box p span {
  font-weight: 700;
}

.pbr-logo {
  width: 100%;
  display: flex;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pbr-logo img {
  max-width: 100%;
}

.video-thumbs {
  width: 100%;
  display: flex;
  text-align: center;
  position: relative;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  justify-content: center;
  align-items: center;
  /*height: 35%;*/
}
.video-thumbs:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 100%;
  top: 12px;
  height: calc(100% - 24px);
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.875rem;
  z-index: 98;
}
.video-thumbs i.playbutton {
  content: "";
  position: absolute;
  width: 4.625rem;
  height: 4.625rem;
  left: 50%;
  top: 50%;
  z-index: 99;
  margin-top: -2.3125rem;
  margin-left: -2.3125rem;
  background: url("../assets/images/playbutton.png");
  background-repeat: no-repeat;
  background-size: 4.625rem 4.625rem;
}

.video-thumbs img {
  width: 100%;
  border-radius: 0.875rem;
}

.profile-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  /* padding-bottom: 0.5rem; */
  /*height: 10%;*/
}

.profile-box-content {
  position: relative;
  /* width: 12.5rem; */
  margin: 0rem auto;
  padding-left: 3rem;
}

.profile-box-content img {
  position: absolute;
  width: 2.813rem;
  height: 2.813rem;
  display: block;
  left: 0rem;
  top: 0rem;
  border-radius: 50%;
}

.profile-box-content label {
  font-size: 1.25rem;
  line-height: 1.375rem;
  color: #fff;
  text-transform: uppercase;
  padding-left: 0.8rem;
  z-index: 99;
  max-height: 44px;
  overflow: hidden;
  width: 100%;
  text-align: left;
  display: block;
  font-weight: 600;
}

.profile-box-content p {
  font-size: 1rem;
  line-height: 1.375rem;
  color: #fff;
  color: #60c9cd;
  padding-left: 1rem;
  padding-right: 0rem !important;
  width: auto !important;
  float: left;
}

.profile-box-content span {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #fff;
  color: #60c9cd;
  position: relative;
  padding-left: 1.25rem;
  font-family: "Proxima Soft";
  float: left;
}

.profile-box-content i {
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #60c9cd;
  font-style: normal;
  background: #60c9cd;
  position: absolute;
  width: 0.313rem;
  height: 0.313rem;
  border-radius: 50%;
  display: inline-block;
  left: 0.4rem;
  top: 0.575rem;
}

.download-box {
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  /*height: 6%;*/
  align-items: center;
}

.download-box a {
  cursor: pointer;
  padding: 0px 15px;
}

.google-play-button {
  width: 157px;
  height: 56px;

  display: inline-block;
  background: url("../assets/images/googleplay.png");
  background-repeat: no-repeat;
  background-size: 157px 56px;
}

.app-store-button {
  width: 158px;
  height: 56px;
  display: inline-block;
  background: url("../assets/images/appstore.png");
  background-repeat: no-repeat;
  background-size: 158px 56px;
}

@media screen and (max-width: 62rem) {
  .pbr-content-box {
    /* width: 90%; */
    height: 100vh;
  }

  .pbr-content-box p {
    /* padding: 0rem 0.8rem; */
    font-size: 1rem;
    line-height: 1.5rem;
    width: 350px;
    margin: 0px auto;
    display: block;
    text-align: center;
  }
  .download-box a {
    padding: 0px 8px;
  }
  .profile-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0.5rem;
  }
  .profile-box-content {
    width: 280px;
    margin: 0px;
  }
}

.loader {
  border: 0.6rem solid #f3f3f3;
  border-radius: 50%;
  border-top: 0.6rem solid #3498db;
  width: 4rem;
  height: 4rem;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  display: flex;
  align-self: center;
  position: absolute;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .pbr-content-box {
    /* width: 50%; */
    height: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .pbr-content-box p {
    width: 350px;
    padding: 0rem 0.7rem;
    font-size: 1rem;
    text-align: justify;
  }
  .video-thumbs {
    width: 100%;
    display: block;
    text-align: center;
    position: relative;
    padding-bottom: 1rem;
    padding-top: 1rem;
    display: inline-block;
    /* height: 20vh;*/
  }
  .profile-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0.5rem;
  }
  .profile-box-content {
    width: 280px;
    margin: 0px;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 1) {
  .pbr-content-box {
    /* width: 90%; */
  }
  .profile-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0.5rem;
  }
  .profile-box-content {
    width: 280px;
    margin: 0px;
  }
}
/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .pbr-content-box {
    /* width: 90%; */
  }
  .pbr-logo {
    /* padding-bottom: 1rem;
    padding-top: 1rem; */
    /* height: 15%;*/
  }
  .profile-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .profile-box-content {
    width: 280px;
    margin: 0px;
  }
  .profile-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 1rem;
  }
  .profile-box-content {
    width: 280px;
    margin: 0px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .pbr-content-box {
    width: 90%;
  }
  .video-thumbs img {
    /* margin-left: 1rem;
    margin-right: 1rem; */
  }
  .profile-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0.5rem;
  }
  .profile-box-content {
    width: 280px;
    margin: 0px;
  }
}
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 1) {
  .video-thumbs {
    width: 100%;
    display: block;
    text-align: center;
    position: relative;
    padding-bottom: 1rem;
    padding-top: 1rem;
    display: inline-block;
  }
  .pbr-content-box p {
    width: 360px;
  }
}
